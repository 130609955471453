import { APP_TOKEN } from '../editor-app-module/constants';

const RAVEN_INIT_STRING = 'https://d71c47c01a6e4b2a9c1cf460e3f76627@sentry.wixpress.com/27';

let fedopsInstance, sentryMonitor;

async function initializeMonitoring(editorSDK, options = {}) {
  const [metaSiteId, siteRegion, siteLanguage] = await Promise.all([
    editorSDK.info.getMetaSiteId(APP_TOKEN),
    editorSDK.info.getSiteRegion(APP_TOKEN),
    editorSDK.info.getLanguage(APP_TOKEN),
  ]);
  const tags = {
    metaSiteId,
    siteRegion,
    siteLanguage,
  };
  if (typeof options.firstInstall !== 'undefined') {
    tags.firstInstall = options.firstInstall;
  }
  if (options.origin && options.origin.type) {
    tags.type = options.origin.type;
  }

  const configuration = {
    dataCallback: () => {},
    tags,
  };
  sentryMonitor = options?.monitoring?.createSentryMonitorForApp(RAVEN_INIT_STRING, configuration);

  fedopsInstance = options?.monitoring?.createFedopsLogger();

  if (!sentryMonitor || !fedopsInstance) {
    console.error(`Issues while initialising loggers. Sentry: ${sentryMonitor}, Fedops: ${fedopsInstance}`);
  }

  await editorSDK.document.errors.registerToErrors('', {
    callback: ({ error, methodName }) => {
      console.log(`An error occured in DS, method ${methodName}, error: ${error}`);
      log('Error in DS:' + error, { tags: { methodName } });
    },
  });
}

/* eslint-disable no-unused-expressions */
function interactionStarted(interactionName) {
  try {
    fedopsInstance.interactionStarted(interactionName);
  } catch (e) {
    const err = 'Failed to start fedops interaction, reason: ' + e;
    sentryMonitor?.captureException(err);
  }
}

function interactionEnded(interactionName) {
  try {
    fedopsInstance.interactionEnded(interactionName);
  } catch (e) {
    const err = 'Failed to end fedops interaction, reason: ' + e;
    sentryMonitor?.captureException(err);
  }
}

function interactionFailed(interactionName, err) {
  sentryMonitor?.captureException(err, { tags: { interactionName } });
}

async function toMonitored(interactionName, promise) {
  try {
    interactionStarted(interactionName);
    const response = await promise();
    interactionEnded(interactionName);
    return response;
  } catch (err) {
    interactionFailed(interactionName, err);
    throw err;
  }
}

function log(message, options = {}) {
  sentryMonitor?.captureMessage(message, { level: 'info', ...options });
}

function logError(err, extra = {}) {
  sentryMonitor?.captureException(err, extra);
}

export { initializeMonitoring, interactionStarted, interactionEnded, interactionFailed, toMonitored, log, logError };
