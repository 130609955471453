import { addApplications } from './platform-api/addApplications';
import * as pages from './pages';
import * as pagesWrapper from './wrappers/pages.ts';
import * as constants from './constants';
import * as pagesGroup from './wrappers/pagesGroup';
import * as routers from './wrappers/routers';
import * as components from './wrappers/components';
import * as controllers from './wrappers/controllers';
import * as menus from './wrappers/menus';
import { allSettled } from '../utils/promises';
import { toMonitored, log } from '../utils/monitoring';
import { isHorizontalLayoutEnabled, areAppWidgetsEnabled } from '../utils/experiments';
import { getIsResponsiveEditor, getIsADI } from './services/applicationState';

const { APP_TOKEN } = constants;

async function shouldInstall(editorSDK, isFirstInstall) {
  if (!isFirstInstall) {
    return false;
  }
  const routersArr = await routers.getAll(editorSDK);
  return routersArr.length === 0;
}

async function installRouters(editorSDK) {
  const addPrivateRouter = routers.add(editorSDK, constants.ROUTERS.PRIVATE);
  const addPublicRouter = routers.add(editorSDK, constants.ROUTERS.SOCIAL);

  await Promise.all([addPrivateRouter, addPublicRouter]);
}

async function addAccountInfo(editorSDK, origin = {}, isHorizontal) {
  const definition = constants.getMyAccountInstallDefinition(origin);
  return addApplications({ editorSDK, applications: [definition], forceHorizontalLayout: isHorizontal });
}

// For investigation purposes of MA-84
async function verifyMyAccountPage(editorSDK) {
  const myAccountAppData = await editorSDK.tpa.app.getDataByAppDefId(APP_TOKEN, '14cffd81-5215-0a7f-22f8-074b0e2401fb');
  const loginMenuItems = await menus.getMenuItems({ editorSDK, menuId: constants.MENU_IDS.LOGIN_MENU_ID });
  const myAccountMenuItem = loginMenuItems.find((i) => i.link.innerRoute === 'my-account');
  const allPages = await editorSDK.pages.data.getAll();
  const myAccountPage = allPages.find((p) => p.tpaPageId === 'member_info');

  if (!myAccountPage && !!myAccountMenuItem) {
    log('Installation: MA-84 My account menu item is there, but the page is missing');
    return;
  }

  // MA-84 JIRA issue tracking
  if (!myAccountAppData && !!myAccountMenuItem) {
    log('My account menu item is there but the app data is missing');
    return;
  }

  if (!myAccountAppData) {
    log('My Account app data is missing');
    return;
  }

  const widget = await editorSDK.tpa.app.getAllCompsByApplicationId(APP_TOKEN, myAccountAppData.applicationId);

  // Maybe we should throw an error and break the installation here, or try to fix it up somehow
  if (!widget) {
    log('My Account page data is existing but the widget is missing');
  }
}

// We see missing routers in sites so trying to verify whether the installation performs as expected
async function verifyRouters(editorSDK) {
  let error = '';
  let installedRouters;

  try {
    installedRouters = await editorSDK.routers.getAll();
  } catch (e) {
    error = e.toString();
  }

  if (!installedRouters || installedRouters.length === 0 || installedRouters.length === 1) {
    const extra = JSON.stringify({ routers, error });
    log('Routers are not installed properly although the installation was successful', { extra });
  }
}

async function maybeDeleteSOSPContainer(editorSDK) {
  // No need to navigate and delete SOSP of it is not apparent
  // These are some corner cases like this in ADI, but we're not sure when this happens
  const SOSPRef = await components.getSOSPContainerRef(editorSDK);
  if (!SOSPRef) {
    return;
  }

  // No need for the SOSP to be visible when deleting in EditorX
  if (getIsResponsiveEditor()) {
    return components.removeSospContainer(editorSDK);
  }

  // SOSP container needs to be visible in order to be deleted
  const isInMembersAreaSubPage = await pages.isInMembersAreaSubPage(editorSDK);
  if (isInMembersAreaSubPage) {
    await components.removeSospContainer(editorSDK);
    await pages.navigateToHomePage(editorSDK);
  } else {
    const startingPageRef = await pagesWrapper.getCurrentPage({ editorSDK });
    await pages.navigateToFirstPrivatePage(editorSDK);
    await components.removeSospContainer(editorSDK);
    await pagesWrapper.navigateToPageRef({ editorSDK, pageRef: startingPageRef });
  }
}

async function uninstall(editorSDK) {
  const isResponsiveEditor = getIsResponsiveEditor();
  try {
    if (isResponsiveEditor) {
      await pages.navigateToHomePage(editorSDK);
    }

    await maybeDeleteSOSPContainer(editorSDK);
    await pagesGroup.remove(editorSDK);
    await routers.removeConnectedPages(editorSDK);
    await routers.removeAllRouters(editorSDK);
    await controllers.wipeOut(editorSDK);
    await menus.removeMenus(editorSDK);
    await editorSDK.history.add(APP_TOKEN, { label: 'deleting members app' });
    await editorSDK.editor.save();
  } catch (e) {
    const message = 'Failed to uninstall Members Area: ' + typeof e === 'string' ? e : e.message;
    log('Failed to uninstall MA', { tags: { message } });
    throw new Error(message);
  }
}

async function install(editorSDK, options) {
  try {
    const isResponsiveEditor = getIsResponsiveEditor();
    const shouldInstallAppWidgets = isResponsiveEditor || (await areAppWidgetsEnabled());
    const isHorizontal = !getIsADI() && (await isHorizontalLayoutEnabled());
    options = options || {};

    // Batch 1
    const [masterRef, headerRef] = await allSettled([
      toMonitored('install.getSiteStructure', () => editorSDK.siteSegments.getSiteStructure()),
      toMonitored('install.getHeader', () => editorSDK.siteSegments.getHeader()),
    ]);

    // Batch 2
    let controllerRef = null;
    if (!shouldInstallAppWidgets) {
      controllerRef = await toMonitored('install.createController', () =>
        controllers.createController(editorSDK, masterRef),
      );
    }

    const [menuIds, sospContainer] = await allSettled([
      toMonitored('install.createMenus', () => menus.create(editorSDK)),
      toMonitored('install.createSospContainer', () => components.createSospContainer(editorSDK, headerRef, masterRef)),
      toMonitored('install.installRouters', () => installRouters(editorSDK)),
    ]);

    await verifyRouters(editorSDK);

    // Batch 3
    await toMonitored('install.addingMainComponents', () =>
      allSettled([
        toMonitored('install.createPagesGroup', () => pagesGroup.create(editorSDK, constants.MEMBERS_PAGES_GROUP_NAME)),
        toMonitored('install.addAccountInfo', () => addAccountInfo(editorSDK, options.origin, isHorizontal)),
        toMonitored('install.addProfileWidget', () => components.addProfileWidget(editorSDK, sospContainer)),
        toMonitored('install.addLoginButton', () => components.addLoginButton(editorSDK, controllerRef, headerRef)),
        toMonitored('install.addSubPagesMenu', () =>
          components.addSubPagesMenu(editorSDK, menuIds.members, sospContainer, controllerRef, isHorizontal),
        ),
      ]),
    );

    await verifyMyAccountPage(editorSDK);

    // Batch 4
    const sospChildren = await toMonitored('install.getChildren', () =>
      editorSDK.components.getChildren(APP_TOKEN, { componentRef: sospContainer }),
    );
    const compsToDeselect = [sospContainer].concat(sospChildren);

    // Batch 5
    await allSettled([
      toMonitored('install.deselectComponents', () =>
        editorSDK.editor.selection.deselectComponents(APP_TOKEN, { compsToDeselect: compsToDeselect }),
      ),
      toMonitored('install.addComponentToGroup', () =>
        pagesGroup.addComponentToGroup(editorSDK, constants.MEMBERS_PAGES_GROUP_NAME, sospContainer),
      ),
    ]);
  } catch (error) {
    return Promise.reject(error);
  }
}

export { install, shouldInstall, uninstall };
