import * as tpa from '../wrappers/tpa';
import * as constants from '../constants';
import * as pagesService from '../services/pages';
import * as menusService from '../services/menus';
import { log } from '../../utils/monitoring';
import { retryPromise } from '../../utils/promises';
import { getIsResponsiveEditor } from '../services/applicationState';
import * as routersWrapper from '../wrappers/routers';
import * as componentsWrapper from '../wrappers/components';
import * as pagesWrapper from '../wrappers/pages.ts';
import * as routersService from '../services/routers';
import * as layoutsService from '../services/layouts';
import { createBIService } from '../../utils/bi.ts';

const filterNotInstalledApplications = async ({ editorSDK, applications }) => {
  const promises = applications.map((app) => {
    const { appDefinitionId, pageId } = app;

    if (!pageId) {
      return Promise.resolve(app);
    }

    return tpa
      .isAppSectionInstalled({ editorSDK, appDefinitionId, sectionId: pageId })
      .then((isInstalled) => (isInstalled ? false : app));
  });

  const notInstalledApps = await Promise.all(promises);
  return notInstalledApps.filter((app) => !!app);
};

const installSiteApplications = async ({ editorSDK, applications, shouldNavigate }) => {
  const notInstalledAppsPromises = applications.map((app) =>
    tpa
      .isApplicationInstalled({ editorSDK, appDefinitionId: app.appDefinitionId })
      .then((isInstalled) => (isInstalled ? false : app)),
  );

  const notInstalledApps = await Promise.all(notInstalledAppsPromises);

  const installAppPromises = notInstalledApps
    .filter((app) => !!app)
    .map(({ appDefinitionId }) => {
      const addPromise = () => tpa.addApplication(editorSDK, { appDefinitionId, shouldNavigate });
      return retryPromise(addPromise, { delayMs: 500, maxTries: 8, message: 'Installing site applications' });
    });

  return Promise.all(installAppPromises);
};

const addApplicationsOrComponents = async ({ editorSDK, applications, shouldNavigate }) => {
  const biService = await createBIService({ editorSDK });
  const promises = applications.map((definition) => {
    const addingMethod = definition.method;
    delete definition.method;

    const addAction = () =>
      addingMethod === 'addApplication'
        ? tpa.addApplication(editorSDK, definition)
        : tpa.addComponent(editorSDK, definition);
    return retryPromise(addAction, { delayMs: 500, maxTries: 8, message: addingMethod });
  });

  const navigateToAddedApps = (addedApps) => {
    const pageRef = addedApps[0]?.pageRef;
    pagesWrapper.navigateToPageRef({ editorSDK, pageRef });

    return addedApps;
  };

  const verifyAddedApplicationsOrComponents = (addedApps) => {
    const verifyPromises = applications.map((definition) =>
      tpa
        .isAppSectionInstalled({
          editorSDK,
          appDefinitionId: definition.appDefinitionId,
          sectionId: definition.page.pageId,
        })
        .then((isInstalled) => {
          if (!isInstalled) {
            const tags = { pageId: definition.page.pageId, appDefinitionId: definition.appDefinitionId };
            log('App installation failed verification just after the successful installation', { tags });
          } else {
            biService.maPageInstalled({ originAppId: definition.appDefinitionId, pageName: definition.page.pageId });
          }
        }),
    );

    return Promise.all(verifyPromises).then(() => (shouldNavigate ? navigateToAddedApps(addedApps) : addedApps));
  };

  // TO DO: ask platform to improve to accept managingAppDefId while installing so we wouldn't need to update this here later
  const updateWithManagingAppDefId = (addedApps) => {
    return pagesService.shouldUsePlatformisedPagesPanel({ editorSDK }).then((isEnabled) => {
      if (isEnabled) {
        const updatePromises = addedApps.map(({ pageRef }) =>
          pagesService.updatePageWithManagingAppDefId({ editorSDK, pageRef }),
        );
        return Promise.all(updatePromises).then(() => addedApps);
      } else {
        return addedApps;
      }
    });
  };

  return Promise.all(promises)
    .then(updateWithManagingAppDefId)
    .then(verifyAddedApplicationsOrComponents)
    .catch((e) => {
      throw new Error('Failed to add multiple applications and components: ' + e);
    });
};

const createApplicationsDefinitions = ({ applications, isHorizontalLayout }) =>
  applications.map((app) => ({
    method: app.method,
    appDefinitionId: app.appDefinitionId,
    componentType: 'PAGE',
    shouldNavigate: !!app.shouldNavigate,
    page: {
      pageId: app.pageId,
      requireLogin: !app.social,
      shouldNavigate: !!app.shouldNavigate,
      showInLoginMenu: !!app.showInLoginMenu,
    },
    ...(isHorizontalLayout ? constants.SECTION_DEFAULT_LAYOUT_HORIZONTAL : constants.SECTION_DEFAULT_LAYOUT),
  }));

const getConnectionConfigUrls = ({ page, app, routers }) => {
  const { publicRouter, privateRouter } = routers;
  const router = app.social ? publicRouter : privateRouter;
  const allRoutes = (router.config && router.config.patterns && Object.keys(router.config.patterns)) || [];
  const urlOverride = app.urlOverride && routersWrapper.createNewPageRoute(allRoutes, app.urlOverride);
  const pageUriSEO = page && page.pageUriSEO && routersWrapper.createNewPageRoute(allRoutes, page.pageUriSEO);

  return { pageUriSEO, urlOverride };
};

const createConnectionConfigs = ({ applications, pages, routers }) =>
  applications.map((app, index) => {
    const isPrivate = !app.social;
    const page = pages[index];
    const isNotifications = app.pageId === 'notifications_app';
    const { urlOverride, pageUriSEO } = getConnectionConfigUrls({ page, app, routers })

    const routerConfig = {
      socialHome: !!app.socialHome,
      pageId: page.id,
      appData: {
        numbers: app.numbers,
        appDefinitionId: app.appDefinitionId,
        appPageId: app.pageId,
        menuOrder: app.menuOrder || constants.DEFAULT_MENU_ORDER,
        visibleForRoles: app.visibleForRoles || [],
      },
    };

    return {
      routerConfig,
      urlOverride,
      pageData: {
        isPrivate,
        ...page,
        pageUriSEO,
      },
      showInLoginMenu: app.showInLoginMenu,
      showInMemberMenu: app.showInMemberMenu,
      appDefinitionId: app.appDefinitionId,
      loginMenuTitle: app.loginMenuTitle,
      showInIconsMenu: isNotifications,
      menuIds: menusService.getMembersAreaMenuIds(),
    };
  });

const installMembersAreaApplications = async ({ editorSDK, applications, forceHorizontalLayout, shouldNavigate }) => {
  const isHorizontalLayout =
    getIsResponsiveEditor() ||
    forceHorizontalLayout ||
    (await layoutsService.isMyAccountLayoutHorizontal({ editorSDK }));

  const applicationsDefinitions = createApplicationsDefinitions({ applications, isHorizontalLayout });
  const createdPages = await addApplicationsOrComponents({
    editorSDK,
    applications: applicationsDefinitions,
    shouldNavigate,
  });
  const routers = (await routersService.getMembersAreaRouters(editorSDK)) || { publicRouter: {}, privateRouter: {} };
  const connectionConfigs = createConnectionConfigs({ applications, pages: createdPages, routers });
  await pagesService.connectPagesToMembers({ editorSDK, pages: connectionConfigs });

  if (!isHorizontalLayout) {
    await componentsWrapper.fixSOSPHeightForVerticalLayout({ editorSDK });
  }
  await pagesService.setStateForPages(editorSDK);
};

const addApplications = async ({ editorSDK, applications, forceHorizontalLayout, shouldNavigate = false }) => {
  const siteApps = applications.filter((app) => app.method === 'addApplicationToSite');
  const membersAreaApps = applications.filter((app) => app.method !== 'addApplicationToSite');
  const filteredMembersAreaApps = await filterNotInstalledApplications({ editorSDK, applications: membersAreaApps });

  return Promise.all(
    [
      siteApps.length > 0 && installSiteApplications({ editorSDK, applications: siteApps, shouldNavigate }),
      filteredMembersAreaApps.length > 0 &&
        installMembersAreaApplications({
          editorSDK,
          applications: filteredMembersAreaApps,
          forceHorizontalLayout,
          shouldNavigate,
        }),
    ].filter((p) => !!p),
  );
};

export { addApplications, createConnectionConfigs, createApplicationsDefinitions };
