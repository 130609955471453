import { log } from '../utils/monitoring';

let lastAction = Promise.resolve();

export default async function next(nextAction) {
  try {
    await lastAction;
  } catch (e) {
    log('Rejected promise in enforceSequentiality', { extra: { error: JSON.stringify(e) } });
    lastAction = Promise.resolve();
  }

  lastAction = lastAction.then(nextAction);
  return lastAction;
}
